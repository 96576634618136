import styled from '@emotion/styled'
import { Link } from 'gatsby'
import React from 'react'
import antonomyLogo from '../../data/icons//cote-pro/logo/antonomy.png'
import audiLogo from '../../data/icons//cote-pro/logo/audi.png'
import bmwLogo from '../../data/icons//cote-pro/logo/bmw.png'
import fonderieLogo from '../../data/icons//cote-pro/logo/fonderie.png'
import miniLogo from '../../data/icons//cote-pro/logo/mini.png'
import racingLogo from '../../data/icons//cote-pro/logo/racing.png'
import semaineLogo from '../../data/icons//cote-pro/logo/semaine.png'
import sfLogo from '../../data/icons//cote-pro/logo/sf.png'
import triumphLogo from '../../data/icons//cote-pro/logo/triumph.png'
import { breakpoints } from '../../utils/styles'

const Label = () => {
  return (
    <Container>
      <Title>
        Ils nous ont fait
        <br /> confiance
      </Title>
      <ContentBox>
        <IconBox>
          <img style={{ width: '50%' }} src={bmwLogo} />
        </IconBox>
        <IconBox>
          <img style={{ width: '80%' }} src={miniLogo} />
        </IconBox>

        <IconBox>
          <img style={{ width: '60%' }} src={audiLogo} />
        </IconBox>
        <IconBox>
          <img style={{ width: '80%' }} src={triumphLogo} />
        </IconBox>
        <IconBox>
          <img src={antonomyLogo} />
        </IconBox>
        <IconBox>
          <img style={{ width: '50%' }} src={sfLogo} />
        </IconBox>
        <IconBox>
          <img style={{ width: '50%' }} src={racingLogo} />
        </IconBox>
        <IconBox>
          <img src={semaineLogo} />
        </IconBox>
        <IconBox>
          <img src={fonderieLogo} />
        </IconBox>
      </ContentBox>
      <Button to='/contact#contact'>Nous contacter</Button>
    </Container>
  )
}

export default Label

export const Container = styled.div`
  margin-top: 5em;
`

export const Title = styled.p`
  text-align: center;
  font-size: 3em;
  color: #2b5c6b;

  @media (max-width: ${breakpoints.s}px) {
    font-size: 2.1em;
  }
`

export const ContentBox = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
  width: 33em;
  height: 33em;

  @media (max-width: ${breakpoints.s}px) {
    width: 100%;
    height: 100%;
  }
`

export const IconBox = styled.div`
  width: 10em;
  height: 10em;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 70%;
  }
`

export const Button = styled(Link)`
  display: block;
  color: #2b5c6b;
  background-color: #eed7b8;
  font-size: 1.2em;
  padding: 0.5em 1em;
  border-radius: 30px;
  text-decoration: none;
  margin: 2em auto;
  width: fit-content;

  @media (max-width: ${breakpoints.s}px) {
    font-size: 1.3em;
    padding: 0.7em 2.2em;
  }

  :hover {
    background-color: #2b5c6b;
    color: #eed7b8;
    cursor: pointer;
  }
`

import styled from '@emotion/styled'
import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import SEO from './../components/seo'
import HeaderPro from '../components/CotePro/HeaderPro'
import Label from '../components/CotePro/Label'
import ServicePro from '../components/CotePro/ServicePro'
import { breakpoints } from '../utils/styles'

const CoteProPage = () => {
  const image = useStaticQuery(graphql`
    query {
      background: file(
        relativePath: { eq: "images/cote-pro/Coté_Pro-background.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <>
      <SEO title="Coté pro" description="Page Coté pro" />
      <Container background={image.background.childImageSharp.fluid.src}>
      <HeaderPro />
      <ServicePro />
      <Label />
    </Container>
    </>
  )
}

export default CoteProPage

export const Container = styled.div`
  @media (min-width: ${breakpoints.l}px) {
    background-image: url('${props => props.background}');
    background-repeat: no-repeat;
    background-position: right 100%;
    background-size: 40%;
  }
`

import styled from '@emotion/styled'
import React from 'react'
import CateringIcon from '../../data/icons/cote-pro/catering.png'
import EquipeIcon from '../../data/icons/cote-pro/equipe.png'
import PrivatisationIcon from '../../data/icons/cote-pro/privatisation.png'
import { breakpoints } from '../../utils/styles'

const ServicePro = () => {
  return (
    <Container>
      <Title>Nos services</Title>
      <ContentBox colored>
        <ContentIcon style={{ height: '3.8em' }} src={PrivatisationIcon} />
        <Content>
          <ContentTitle gold>Privatisation</ContentTitle>
          <ContentText>
            Ravissez vos salariés, vos meilleurs clients, vos invités lors de
            vos événements ! Nous vous proposons de privatiser nos différents
            modules en fonction de vos besoins.
          </ContentText>
        </Content>
      </ContentBox>

      <ContentBox>
        <ContentIcon src={CateringIcon} />
        <Content>
          <ContentTitle>Catering</ContentTitle>
          <ContentText white>
            Nous proposons des prestations dédiées aux entreprises tels que les
            catering. Réalisation, installation, et service sur mesure en
            fonction de vos demandes.
          </ContentText>
        </Content>
      </ContentBox>

      <ContentBox colored>
        <ContentIcon src={EquipeIcon} />
        <Content>
          <ContentTitle gold>Cohésion d’équipe</ContentTitle>
          <ContentText>
            Lors de vos séminaires ou de vos évènements Team Building, boostez vos équipes en privatisant JH & Co (Animations Latte Art, découverte de l’univers du café en les faisant participer à l’élaboration et extraction de leur propre boisson)
          </ContentText>
        </Content>
      </ContentBox>
    </Container>
  )
}

export default ServicePro

export const Container = styled.div`
  margin-top: 8em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: ${breakpoints.m}px) {
    margin-top: 2em;
  }

  @media (max-width: ${breakpoints.s}px) {
    margin-top: 5em;
  }
`

export const Title = styled.p`
  color: #2b5c6b;
  font-size: 3em;

  @media (max-width: ${breakpoints.s}px) {
    font-size: 2.3em;
  }
`

export const ContentBox = styled.div`
  background-color: ${props => (props.colored ? '#2b5c6b' : 'transparent')};
  width: 38em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1em 4em;
  border-radius: 120px;
  margin-bottom: 2em;
  min-height: 10em;

  @media (max-width: ${breakpoints.m}px) {
    width: 85%;
    padding: 1em 2em;
  }

  @media (max-width: ${breakpoints.s}px) {
    flex-direction: column;
    width: 85%;
    padding: 1em 1em;
    border-radius: 20px;
  }
`

export const Content = styled.div`
  width: 80%;
`

export const ContentTitle = styled.p`
  color: ${props => (props.gold ? '#eed7b8' : '#2b5c6b')};
  font-size: 1.5em;
  margin-bottom: 0.2em;

  @media (max-width: ${breakpoints.s}px) {
    text-align: center;
  }
`

export const ContentText = styled.p`
  color: ${props => (props.white ? '#000000' : 'white')};
  font-family: 'Avenir Light';
  margin-top: 0.5em;

  @media (max-width: ${breakpoints.s}px) {
    font-size: 0.9em;
    text-align: center;
  }
`

export const ContentIcon = styled.img`
  width: 5em;
  height: 5em;
`

import styled from '@emotion/styled'
import { graphql, Link, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import { breakpoints } from '../../utils/styles'

const HeaderPro = () => {
  const images = useStaticQuery(graphql`
    query {
      pro: file(relativePath: { eq: "images/cote-pro/pro-img.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

      background: file(
        relativePath: { eq: "images/cote-pro/pro-background.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Container background={images.background.childImageSharp.fluid.src}>
      <LeftBox>
        <Title>Coté Pro</Title>
        <Text>
          Notre société propose des prestations personnalisées en fonction des
          besoins adaptées a toutes les exigences.
        </Text>
        <Text>
          Nous proposons de privatiser nos différents modules de vente pour tout
          type d'événements. Aussi bien en vente directe qu'en privatisation pour
          des salons ,ventes privées, mariages, lancements commerciaux,
          catering...
        </Text>
        <Button to='/contact#contact'>Ca m’intéresse</Button>
      </LeftBox>

      <RightBox>
        <Img
          style={{ borderRadius: '30px' }}
          fluid={images.pro.childImageSharp.fluid}
        />
      </RightBox>
    </Container>
  )
}

export default HeaderPro

export const Container = styled.div`
  height: 25em;
  padding: 5em 5em 5em 8em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #12282e;
  background-image: url(${props => props.background});
  background-repeat: no-repeat;
  background-size: 80%;
  background-position: 20% 0;

  @media (max-width: 1280px) {
    padding: 2em 2em 2em 3em;
  }

  @media (max-width: ${breakpoints.xl}px) {
    height: 22em;
    background-position: 60% 0;
  }

  @media (max-width: ${breakpoints.l}px) {
    flex-direction: column;
    align-items: center;
    height: 38em;
    padding: 2em 1em;
    background-position: 60% 0;
    margin-bottom: 20em;
  }

  @media (max-width: ${breakpoints.m}px) {
    margin-bottom: 12em;
  }

  @media (max-width: ${breakpoints.s}px) {
    padding-top: 3em;
    height: 35em;
    margin-bottom: 2em;
  }
`

export const LeftBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 28em;

  @media (max-width: ${breakpoints.xl}px) {
    width: 26em;
  }

  @media (max-width: ${breakpoints.l}px) {
    width: 80%;
    height: fit-content;
  }

  @media (max-width: ${breakpoints.s}px) {
    width: 90%;
  }
`

export const Title = styled.div`
  font-size: 3.8em;
  color: #eed7b8;

  @media (max-width: ${breakpoints.l}px) {
    margin-bottom: 0.5em;
  }

  @media (max-width: ${breakpoints.s}px) {
    font-size: 3em;
  }

  @media (max-width: 320px) {
    font-size: 2.5em;
  }
`

export const Text = styled.p`
  text-align: center;
  font-family: 'Avenir Light';
  font-weight: 300;
  color: white;
  font-size: 1.1em;
  margin: 0;

  @media (max-width: ${breakpoints.l}px) {
    margin-bottom: 1em;
  }
`

export const Button = styled(Link)`
  border-radius: 50px;
  background-color: #eed7b8;
  border: 2px solid transparent;
  font-size: 1.2em;
  color: #2b5c6b;
  text-decoration: none;
  padding: 0.5em 1em;
  margin-top: 1em;

  :hover {
    background-color: transparent;
    border: 2px solid #eed7b8;
    color: #eed7b8;
    cursor: pointer;
  }
`

export const RightBox = styled.div`
  width: 60%;
  margin-top: 4em;

  @media (max-width: ${breakpoints.xl}px) {
    width: 50%;
    margin-top: 8em;
  }

  @media (max-width: ${breakpoints.l}px) {
    width: 90%;
    margin-top: 4em;
  }

  @media (max-width: ${breakpoints.s}px) {
    width: 95%;
  }
`
